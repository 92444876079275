import React, { Component } from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE, daysBetween } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, PrimaryButton, FieldDateRangeInput, ActiveProgramModal } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import DatePicker from 'react-datepicker';
import css from './BookingDatesForm.css';
import { addToCartEvent } from '../../analytics/gaEvents';
import computeDiscount from './computeDiscount';
import FieldDateRange from '../../components/FieldDateRangeInput/FieldDateRange';
import QuantitySelector from './QuantitySelector';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      const { promo } = this.props;
      this.props.onSubmit({ ...e, promo });

    }
  }

  handleAddToCart = (e) => {
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      const { listing } = this.props;
      const days = daysBetween(startDate, endDate);
      const discount = computeDiscount({ price: listing.attributes.price.amount, quantity: days, provider: listing.provider.attributes, listing: listing.attributes });
      addToCartEvent({
        currency: listing.attributes.price.currency,
        atcType: 'conversionZone',
        value: (listing.attributes.price.amount * days - discount) / 100,
        item: {
          price: listing.attributes.price.amount / 100,
          id: listing.id.uuid,
          name: listing.attributes.title,
          category: listing.attributes.businessCategory,
          provider: listing.provider.attributes.name,
          // TO-DO Remember when adding quantity field
          quantity: e.quantity
        }
      });
      this.props.onAddToCart({ ...e });
    }
  }

  render() {
    const { promo, rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleAddToCart}
        initialValues={{ quantity: 1 }}
        render={fieldRenderProps => {
          const {
            formId,
            handleSubmit,
            intl,
            minBookingDays,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            form
          } = fieldRenderProps;

          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingDatesForm.bookingEndTitle' });
          const requiredMessage = intl.formatMessage({ id: 'BookingDatesForm.requiredDate' });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.timeSlotsError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                unitType,
                unitPrice,
                startDate,
                endDate,

                // NOTE: If unitType is `line-item/units`, a new picker
                // for the quantity should be added to the form.
                quantity: values.quantity,
              }
              : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                listingData={this.props.listingData}
                withDates={false}
                listing={this.props.listing}
              />
            </div>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const activeProgramInfo =
            (process.env.REACT_APP_COUNTRY_ID === 'pl' && false) ? <ActiveProgramModal /> : null;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FieldDateRange
                name="bookingDates"
                timeSlots={timeSlots}
                inBookingDays={minBookingDays}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                format={identity}
                minBookingDays={minBookingDays}
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                )}
                listing={this.props.listing}
              />
              {
                minBookingDays > 1 &&
                <div className={css.minBookingDaysInfo}>
                  <FormattedMessage id="BookingDatesForm.minBookingDays" />&nbsp;<strong>{minBookingDays}</strong> <FormattedMessage id="BookingDatesForm.days" />
                </div>
              }
              <div className={css.quantitySelector}>
                <span className={css.quantityLabel}><FormattedMessage id="BookingDatesForm.seats" /></span>
                <QuantitySelector name="quantity" bookingDates={values.bookingDates} timeSlots={timeSlots} />
              </div>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" id="ConfirmDatesButton">
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
                {activeProgramInfo}
              </div>
              {bookingInfo}
              <FormSpy
                subscription={{ values: true }}
                onChange={props => {
                  const { values } = props;
                  const { quantity, bookingDates = {} } = values;

                  const selectedTimeSlots = timeSlots ? timeSlots?.filter(slot => {
                    if (!(bookingDates.startDate && bookingDates.endDate)) return false;
                    const slotDate = moment(slot.attributes.start);
                    return slotDate.isBetween(bookingDates.startDate, bookingDates.endDate, 'days', '[]');
                  }) : [];

                  const maxAvailableSlots = Math.min(...selectedTimeSlots.map(slot => slot.attributes.seats));

                  if (bookingDates.startDate && bookingDates.endDate) {
                    form.change('quantity', Math.min(quantity, maxAvailableSlots))
                  }
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  minBookingDays: 1
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
