import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { parse, stringify } from '../../util/urlHelpers';
import DateInput from '../../components/FieldDateInput/DateInput';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import { InlineTextButton, Input, Select } from '../../components';
import AutocompleteSelect from '../../components/AutocompleteSelect/AutocompleteSelect';

import css from './InboxPage.css';

const selectOptions = [
  { value: "all", label: "TransactionFilter.allBookings", sales: true },
  { value: "pending-payment", label: "TransactionFilter.pendingPayment", sales: false },
  { value: "payment-expired", label: "TransactionFilter.paymentExpired", sales: false },
  { value: "accepted", label: "TransactionFilter.accepted", sales: true },
  { value: "picked-up", label: "TransactionFilter.pickedUp", sales: true },
  { value: "delivered", label: "TransactionFilter.delivered", sales: true },
  { value: "cancelled", label: "TransactionFilter.cancelled", sales: true },
];

const statusesForDateFilter = ["accepted", "picked-up", "delivered"];

const TransactionsFilter = ({
  history,
  location,
  providerId,
  currentUserProviders,
  isSales,
  intl,
  pagination
}) => {
  const { pathname, search } = location;
  const { bookingState, notResponded, phrase, startDate, endDate, nonDeliveredOnTime, deliveryState, deliveryToProviderId, deliveryType } = parse(
    search
  );

  const [searchPhrase, setSearchPhrase] = useState(phrase);

  const handleMainFilterChange = event => {
    const state = event.target.value === 'all' ? undefined : event.target.value;
    const baseParams = { notResponded, phrase, deliveryToProviderId };
    const searchParams =
      state === (state === 'cancelled' || event.target.value === 'all')
        ? baseParams
        : { ...baseParams, startDate, endDate };

    history.push(
      `${pathname}?${stringify({
        ...searchParams,
        bookingState: state,
        page: 1,
      })}`
    );
  };

  const handleDeliveryStateChange = (e) => {
    const state = e.target.value === 'all' ? undefined : e.target.value;
    const baseParams = { notResponded, phrase, deliveryToProviderId };
    const searchParams =
      state === (state === 'cancelled' || e.target.value === 'all')
        ? baseParams
        : { ...baseParams, startDate, endDate };

    history.push(
      `${pathname}?${stringify({
        ...searchParams,
        deliveryState: state,
        page: 1,
      })}`
    );
  }

  const handleNotRespondedChange = value => {
    history.push(
      `${pathname}?${stringify({ ...parse(search), notResponded: value.checked || undefined })}`
    );
  };

  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push({ pathname, search: `${stringify({ ...parse(search), phrase: searchPhrase })}` });
  };

  const transactionSearch = (
    <form onSubmit={handleSubmit}>
      <Input
        label={intl.formatMessage({ id: "TransactionFilter.search" })}
        value={searchPhrase}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'TransactionFilter.searchTransaction' })}
      />
    </form>
  );

  const handleStartDateChange = date => {
    const formatDate = date ? date.toISOString().split('T')[0] : undefined;
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), startDate: formatDate, page: 1 })}`,
    });
  };

  const handleEndDateChange = date => {
    const formatDate = date ? date.toISOString().split('T')[0] : undefined;
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), endDate: formatDate, page: 1 })}`,
    });
  };

  const handleDeliveredOnTimeSwitch = value => {
    history.push(
      `${pathname}?${stringify({
        ...parse(search),
        nonDeliveredOnTime: value.checked || undefined,
      })}`
    );
  };

  const handleDeliveryLocationChange = ({ value }) => {
    const params = { ...parse(search), page: 1 };

    if (value === 'home') {
      // Ustawiamy deliveryType na 'home' i usuwamy deliveryToProviderId
      params.deliveryType = 'home';
      delete params.deliveryToProviderId;
    } else if (value === '') {
      // Usuwamy zarówno deliveryType, jak i deliveryToProviderId
      delete params.deliveryType;
      delete params.deliveryToProviderId;
    } else {
      // Ustawiamy deliveryToProviderId i usuwamy deliveryType
      params.deliveryToProviderId = value;
      delete params.deliveryType;
    }

    history.push(`${pathname}?${stringify(params)}`);
  };

  const clearFilters = () => {
    history.push({ pathname });
  };

  const deliveredOnTimeTransactionSwitch = isSales && (
    <div className={css.toggler}>
      <ToggleSwitch
        name={intl.formatMessage({
          id: 'ConsoleTransactionPage.nonDeliveredOnTime',
        })}
        checked={nonDeliveredOnTime}
        onChange={handleDeliveredOnTimeSwitch}
      />
    </div>
  );

  useEffect(() => {
    !phrase && setSearchPhrase('');
  }, [phrase]);

  const selectedProvider = currentUserProviders.find(p => p.id.uuid === providerId);

  const deliveryOptions = [
    { value: '', label: intl.formatMessage({ id: 'TransactionsFilter.all' }) }, // Opcja "Wszystkie"
    { value: 'home', label: intl.formatMessage({ id: 'TransactionsFilter.deliveryToHome' }) }, // Opcja "Dostawa do domu"
    // Istniejące opcje dostawy do sklepów
    ...(selectedProvider?.deliveryToProviders.map(p => ({
      value: p.id.uuid,
      label: p.attributes.name,
    })) || []),
  ];

  // Ustawiamy wartość wejściową dla AutocompleteSelect
  const deliveryInputValue = deliveryType === 'home'
    ? { value: 'home', label: intl.formatMessage({ id: 'TransactionsFilter.deliveryToHome' }) }
    : deliveryToProviderId
      ? deliveryOptions.find(option => option.value === deliveryToProviderId) || { value: deliveryToProviderId }
      : { value: '', label: intl.formatMessage({ id: 'TransactionsFilter.all' }) };

  return (
    <div className={css.filter}>
      <Select
        value={bookingState || 'all'}
        onChange={handleMainFilterChange}
        label={intl.formatMessage({ id: "TransactionFilter.bookingStatus" })}
      >
        {selectOptions.map(({ value, label, sales }) =>
          !isSales || sales ? (
            <option value={value}>{intl.formatMessage({ id: label })}</option>
          ) : null
        )}
      </Select>
      {isSales && <div>{transactionSearch}</div>}
      {statusesForDateFilter.includes(bookingState) && isSales && (
        <div className={css.additionalFilters}>
          <div className={css.filterWrapper}>
            <div className={css.labelWithClearButton}>
              <label>{intl.formatMessage({ id: 'TransactionsFilter.dateStart' })}</label>
              {!!startDate && (
                <button className={css.clearButton} onClick={() => handleStartDateChange(null)}>
                  {intl.formatMessage({ id: 'TransactionsFilter.clear' })}
                </button>
              )}
            </div>
            <DateInput
              className={css.test}
              isOutsideRange={() => false}
              onChange={d => handleStartDateChange(d.date)}
              onFocus={() => { }}
              onBlur={() => { }}
              placeholderText={intl.formatMessage({
                id: 'TransactionsFilter.dateStartPlaceholder',
              })}
              value={{ date: startDate ? new Date(startDate) : null }}
            />
          </div>
          <div className={css.filterWrapper}>
            <div className={css.labelWithClearButton}>
              <label>{intl.formatMessage({ id: 'TransactionsFilter.dateEnd' })}</label>
              {!!endDate && (
                <button className={css.clearButton} onClick={() => handleEndDateChange(null)}>
                  {intl.formatMessage({ id: 'TransactionsFilter.clear' })}
                </button>
              )}
            </div>
            <DateInput
              className={css.test}
              isOutsideRange={() => false}
              onChange={d => handleEndDateChange(d.date)}
              onFocus={() => { }}
              onBlur={() => { }}
              placeholderText={intl.formatMessage({ id: 'TransactionsFilter.dateEndPlaceholder' })}
              value={{ date: endDate ? new Date(endDate) : null }}
            />
          </div>
        </div>
      )}
      {selectedProvider?.attributes.isWarehouse && (
        <div className={css.filterWrapper}>
          <AutocompleteSelect
            inputValue={{ value: deliveryInputValue.value }}
            onChange={handleDeliveryLocationChange}
            label={intl.formatMessage({ id: "TransactionsFilter.location" })}
            options={deliveryOptions}
            placeholder={intl.formatMessage({ id: "TransactionsFilter.location" })}
          />
        </div>
      )}
      {
        process.env.REACT_APP_COUNTRY_ID === 'pl' &&
        <div>
          <Select
            value={deliveryState || 'all'}
            onChange={handleDeliveryStateChange}
            label="Status dostawy"
          >
            <option value="all">Wszystkie</option>
            <option value="not-delivered">oczekuje na wysyłkę do sklepu</option>
            <option value="sent-to-store">wysłano do sklepu</option>
            <option value="received-by-store">odebrano w sklepie</option>
            <option value="sent-to-stock">wysłano do magazynu</option>
            <option value="received">odebrano w magazynie</option>
          </Select>
        </div>
      }
      {deliveredOnTimeTransactionSwitch}
      {isSales && (
        <div className={css.togglerAndClear}>
          <InlineTextButton className={css.clearBtn} onClick={clearFilters}>
            {intl.formatMessage({ id: 'TransactionFilter.clear' })}
          </InlineTextButton>
          <ToggleSwitch
            name={intl.formatMessage({ id: 'TransactionFilter.onlyNotResponded' })}
            checked={notResponded}
            onChange={handleNotRespondedChange}
          />
        </div>
      )}
      {pagination?.totalItems > 0 && <div className={css.elements}><FormattedMessage id="Console.numberOfItems" />:<span className={css.number}>{pagination?.totalItems}</span></div>}
    </div>
  );
};

export default withRouter(injectIntl(TransactionsFilter));
