import React, { useState, useEffect, forwardRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import isNull from 'lodash/isNull';

import { IconArrowHead, Input, ValidationError } from '..';
import { openCalendarEvent } from '../../analytics/gaEvents';

import css from './FieldDateRange.css';

const dayOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const DateRange = ({
  timeSlots,
  minBookingDays,
  startDateId,
  endDateId,
  startDateLabel,
  endDateLabel,
  input,
  meta,
  listing
}) => {
  const { value, onChange, onBlur } = input;
  const startDate = value?.startDate || null;
  const endDate = value?.endDate || null;
  const [excludeDates, setExcludeDates] = useState([]);
  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const [shouldClose, setShouldClose] = useState(true);
  const [message, setMessage] = useState('');
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    const dateArray = value.split(' ');
    const start = dateArray.length > 1 ? dateArray[0] : '';
    const end = dateArray.length === 3 ? dateArray[2] : '';
    const formattedStart = start ? moment(start).format('dd, D MMM') : '';
    const formattedEnd = end ? moment(end).format('dd, D MMM') : '';
    const defaultStart = moment(new Date()).format('dd, D MMM');
    const defaultEnd = moment(new Date())
      .add(1, 'days')
      .format('dd, D MMM');
    const startText = !start ? defaultStart : `${formattedStart}`;
    const endText = !end ? defaultEnd : `${formattedEnd}`;
    const endDateError = !!meta.error && !!start && !end ? meta.error : '';
    const startClassName = !start ? classNames(css.defaultBtn, {[css.active]: isCalendarOpened }) : css.btn;
    const endClassName = !end
      ? classNames(css.defaultBtn, { [css.errorBtn]: (!!endDateError)})
      :  css.btn;

    return (
    <div className={css.container} ref={ref}>
      <div className={css.dateContainer}>
        <label htmlFor={startDateId} className={css.label}>
          <span className={css.text}>{startDateLabel}</span>
          <div className={startClassName} onClick={onClick}>
            {startText}{' '}
          </div>
        </label>
      </div>
      <div className={css.dateContainer}>
          <label htmlFor={endDateId} className={css.label}>
            <span className={css.text}>{endDateLabel}</span>
            <div className={endClassName} onClick={onClick}>
              {endText}
            </div>
          </label>
        </div>
      </div>
    );
  });

  const changeMaxDate = (timeSlots, start) => {
    let startIndex = null;
    let newMaxDate = null;

    timeSlots.forEach((element, index) => {
      if (moment(start).format('YYYY-MM-DD') === element.attributes.start) {
        startIndex = index;
      }

      if (!newMaxDate && !isNull(startIndex) && startIndex !== index) {
        newMaxDate = !element.attributes.seats ? element.attributes.start : null;
      }
    });

    newMaxDate && setMaxDate(new Date(newMaxDate));
  };

  const initMaxDate = () => {
    const storeClosingTimeSlots = timeSlots?.filter(
      item => !item.attributes.opened || !(item.attributes.seats > 0)
    );
    const storeClosingDate = storeClosingTimeSlots?.map(item => new Date(item.attributes.start));
    setExcludeDates(storeClosingDate);
    setMinDate(new Date());

    if (timeSlots?.length) {
      const lastAvailableDate = timeSlots[timeSlots.length - 1].attributes.start;
      setMaxDate(new Date(lastAvailableDate));
    }
  };

  const handleChange = (updates, event) => {
    const [start, end] = updates;
    if (!end && !start) {
      onChange({ startDate: start, endDate: end });
    } else {
      if (end !== endDate) {
        if (end) {
          const numberOfRentalDays = moment(end).diff(moment(start), 'days') + 1;
          if (!minBookingDays || numberOfRentalDays >= minBookingDays) {
            onChange({ startDate: start, endDate: end });
          } else {
            setMessage(
              <div className={css.error}>
                <FormattedMessage
                  id="FieldDateRange.bookingNotPossible"
                  values={{ numberOfRentalDays, minBookingDays }}
                />
              </div>
            );
          }
        } else onChange({ startDate: start, endDate: end });
      } else onChange({ startDate: start, endDate: end });
    }
  };

  const handleDayMouseEnter = date => {
    if (startDate && !endDate) {
      const numberOfRentalDays = moment(date).diff(moment(startDate), 'days') + 1;
      setShouldClose(!minBookingDays || numberOfRentalDays >= minBookingDays);
    }
  };

  const handleCalendarClose = () => {
    setIsCalendarOpened(false);
    setMinDate(new Date());
    setMaxDate(null);
    setMessage('');
    (!startDate || !endDate) && onChange({ startDate: null, endDate: null });
  };


  const handleCalendarOpen = () => {
    setIsCalendarOpened(true);

    openCalendarEvent({
      currency: listing.attributes.price.currency,
      item: {
        id: listing.id.uuid,
        name: listing.attributes.title,
        category: listing.attributes.businessCategory,
        price: listing.attributes.price.amount / 100,
        provider: listing.provider.attributes.name,
        quantity: 1
      },
      value: listing.attributes.price.amount / 100
    })
  }

  useEffect(() => {
    if (startDate && timeSlots?.length) {
      changeMaxDate(timeSlots, startDate);
      setMinDate(new Date(startDate));
    }
    !startDate && initMaxDate();
  }, [startDate]);

  useEffect(() => {
    initMaxDate();
  }, [timeSlots]);

  return (
    <div className={css.wrapper}>
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        excludeDates={excludeDates}
        dateFormat="yyyy-MM-dd"
        selectsRange
        customInput={<CustomInput />}
        shouldCloseOnSelect={shouldClose}
        onDayMouseEnter={handleDayMouseEnter}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        showDisabledMonthNavigation
        selectsDisabledDaysInRange
        onBlur={onBlur}
        calendarStartDay={1}
        formatWeekDay={nameOfDay => {
          const dayIndex = dayOfWeek.indexOf(nameOfDay);
          const localDaysOfDays = moment.weekdaysShort(true);
          return <p className={css.dayNameText}>{localDaysOfDays[dayIndex]}</p>;
        }}
        dayClassName={date => <p className={css.day}>{date}</p>}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          return (
            <div className={css.headerContainer}>
              <p className={css.calendarTitle}>
                <FormattedMessage id="FieldDateRange.chooseDates" />
              </p>
              <div className={css.monthContainer}>
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className={css.arrowBtn}
                  type="button"
                >
                  <IconArrowHead direction={'left'} />
                </button>
                <p className={classNames(css.month)}>
                  {moment(date).format('MMMM')} {moment(date).format('YYYY')}
                </p>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  className={css.arrowBtn}
                  type="button"
                >
                  <IconArrowHead direction={'right'} />
                </button>
              </div>
            </div>
          );
        }}
        calendarClassName={css.calendar}
        popperClassName={css.popper}
      >
        {message}
      </DatePicker>
      <ValidationError fieldMeta={meta} className={css.validationError} />
    </div>
  );
};

const FieldDateRange = props => {
  return <Field component={DateRange} {...props} />;
};

export default FieldDateRange;
