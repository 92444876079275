import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingDatesForm } from '../../forms';

import css from './BookingPanel.css';

const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, setIsBook) => {
  if (isClosed) {
    window.scrollTo(0, 0);
  } else {
    // const { pathname, search, state } = location;
    // const searchString = `?${stringify({ ...parse(search), book: true })}`;
    // history.push(`${pathname}${searchString}`, state);
    setIsBook(true);
  }
};

const closeBookModal = (setIsBook) => {
  // const { pathname, search, state } = location;
  // const searchParams = omit(parse(search), 'book');
  // const searchString = `?${stringify(searchParams)}`;
  // history.push(`${pathname}${searchString}`, state);
  setIsBook(false);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    onAddToCart,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    promo,
    addToCartSuccess
  } = props;

  const [isBook, setIsBook] = useState(false);

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  // const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const listingData = listing ? {
    name: listing.attributes.title,
    productId: listing.id.uuid,
    price: listing.attributes.price.amount / 100,
    category:listing.attributes.category,
    brand: listing.provider?.attributes?.name,
    provider: listing.provider?.attributes,
    businessCategory: listing.attributes.businessCategory
  } : {};

  useEffect(() => {
    addToCartSuccess && setIsBook(false);
  },[addToCartSuccess])

  return (
    <div className={classes}>
      <ModalInMobile
        className={css.container}
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(setIsBook)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          <div><span className={css.price}>{formattedPrice}</span><span className={css.unit}> / <FormattedMessage id="ListingCard.perDay" /></span></div>
          {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}

        </div>
        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            onAddToCart={onAddToCart}
            price={price}
            isOwnListing={isOwnListing}
            bookingConditions={{ openingPattern: listing.provider?.attributes.openingPattern }}
            minBookingDays={listing.attributes.minBookingDays || listing.provider?.attributes.minBookingDays}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            listingData={listingData}
            promo={promo}
            listing={listing}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.header}><FormattedMessage id="BookingPanel.chooseDates"/></div>
          <div>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice} <span className={css.perUnit}><FormattedMessage id="BookingPanel.per1Day" /></span>
            </div>
          </div>
        </div>

        {showBookingDatesForm ? (
          <>
            <Button
              id="BookButton"
              rootClassName={css.bookButton}
              onClick={() => openBookModal(isOwnListing, isClosed, setIsBook)}
            >
              {/* <FormattedMessage id="BookingPanel.ctaButtonMessage" /> */}
              <FormattedMessage id="BookingPanel.checkAvailabilty" />
            </Button>
          </>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
