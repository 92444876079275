import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, nonEmptyArray, validBusinessURL } from '../../util/validators';
import { currencyConfiguration } from '../../currency-config';
import { Form, Button, FieldTextInput, FieldSelect, AddImages, ValidationError, FieldCurrencyInput } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import BusinessCategorySelectFieldMaybe from './BusinessCategorySelectFieldMaybe';
import css from './EditProductForm.css';

const TITLE_MAX_LENGTH = 60;
const ACCEPT_IMAGES = 'image/*';

const getAvailableCountries = (user) => {
  if (!user) {
    return [];
  }

  const COUNTRIES = [
    { value: 'pl', label: 'Poland' },
    { value: 'cz', label: 'Czech Republic' },
    { value: 'hu', label: 'Hungary' },
    { value: 'sk', label: 'Slovakia' },
    { value: 'ee', label: 'Estonia' },
    { value: 'lt', label: 'Lithuania' },
    { value: 'lv', label: 'Latvia' }
  ];

  if (user.attributes.isAdmin) {
    return COUNTRIES; // Admin has access to all countries
  } else {
    return COUNTRIES.filter(country => user.attributes.adminForCountries.includes(country.value));
  }
}

const EditProductFormComponent = props => {
  const [imageUploadRequested, setImageUploadRequested] = useState(false);

  const onImageUploadHandler = (file) => {
    if (file) {
      setImageUploadRequested(true);
      props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  }

  return (
    <FinalForm
      {...props}
      enableReinitialize={true}
      render={formRenderProps => {
        const {
          categories,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          fetchErrors,
          values,
          form,
          submitInProgress,
          images,
          onRemoveImage,
          onUpdateImageOrder,
          currentUser
        } = formRenderProps;

        const titleMessage = intl.formatMessage({ id: 'EditProductForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditProductForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditProductForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditProductForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditProductForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditProductForm.descriptionPlaceholder',
        });
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditProductForm.descriptionRequired',
        });

        const modelCodeLabel = intl.formatMessage({
          id: 'EditProductForm.modelCodeLabel',
        });

        const modelCodePlaceholder = intl.formatMessage({
          id: 'EditProductForm.modelCodePlaceholder',
        });

        const priceLabel = intl.formatMessage({
          id: 'EditProductForm.priceLabel',
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditProductForm.pricePlaceholder',
        });

        const priceRequiredMessage = intl.formatMessage({
          id: 'EditProductForm.priceRequired',
        });

        const countryLabel = intl.formatMessage({
          id: 'EditProductForm.countryLabel',
        });

        const countryPlaceholder = intl.formatMessage({
          id: 'EditProductForm.countryPlaceholder',
        });

        const imageRequiredMessage = intl.formatMessage({
          id: "EditListingPhotosForm.imgRequired"
        });

        const externalLinkLabel = intl.formatMessage({
          id: 'EditItemForm.externalLinkLabel',
        });

        const externalLinkPlaceholder = intl.formatMessage({
          id: 'EditItemForm.externalLinkPlaceholder',
        });

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProductForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProductForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProductForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitDisabled = invalid || disabled || submitInProgress;

        const countriyOptions = getAvailableCountries(currentUser);

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            <AddImages
              className={css.imagesField}
              images={images}
              thumbnailClassName={css.thumbnail}
              savedImageAltText={intl.formatMessage({
                id: 'EditListingPhotosForm.savedImageAltText',
              })}
              onRemoveImage={onRemoveImage}
              onUpdateImageOrder={onUpdateImageOrder}
              form={form}
            >
              <Field
                id="addImage"
                name="addImage"
                accept={ACCEPT_IMAGES}
                form={null}
                label={intl.formatMessage({ id: 'EditListingPhotosForm.chooseImg' })}
                type="file"
                disabled={imageUploadRequested}
              >
                {fieldprops => {
                  const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    form.change(`addImage`, file);
                    form.blur(`addImage`);
                    onImageUploadHandler(file);
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return (
                    <div className={css.addImageWrapper}>
                      <div className={css.aspectRatioWrapper}>
                        {fieldDisabled ? null : (
                          <input {...inputProps} className={css.addImageInput} />
                        )}
                        <label htmlFor={name} className={css.addImage}>
                          {label}
                        </label>
                      </div>
                    </div>
                  );
                }}
              </Field>

              <Field
                component={props => {
                  const { input, meta } = props;
                  return (
                    <div className={css.imageRequiredWrapper}>
                      <input {...input} />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  );
                }}
                name="images"
                type="hidden"
                validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
              />
            </AddImages>

            <FieldSelect
              id="countryId"
              name="countryId"
              className={css.category}
              label={countryLabel}
              validate={required(countryLabel)}
            >
              <option disabled value="">
                {countryPlaceholder}
              </option>
              {
                countriyOptions.map(country => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))
              }

            </FieldSelect>

            <CustomCategorySelectFieldMaybe
              id="category"
              name="category"
              categories={categories}
              intl={intl}
              form={form}
              markRequired={true}
            />

            <BusinessCategorySelectFieldMaybe
              id="businessCategory"
              name="businessCategory"
              intl={intl}
              category={values.category}
              businessCategory={values.businessCategory}
              markRequired={true}
            />

            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage))}
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage))}
            />

            <FieldCurrencyInput
              id="price"
              name="price"
              disabled={!values.countryId}
              className={css.priceInput}
              label={priceLabel}
              placeholder={pricePlaceholderMessage}
              currencyConfig={currencyConfiguration('PLN')}
              validate={required(priceRequiredMessage)}
              markRequired={true}
            />

            <FieldTextInput
              id="modelCode"
              name="modelCode"
              className={css.description}
              type="text"
              label={modelCodeLabel}
              placeholder={modelCodePlaceholder}
            />

            <FieldTextInput
              id="externalLink"
              name="externalLink"
              className={css.description}
              type="text"
              label={externalLinkLabel}
              placeholder={externalLinkPlaceholder}
              validate={composeValidators(validBusinessURL(intl.formatMessage({ id: 'EdistListingDescriptionForm.invalidUrl' })))}
            />

            <FieldTextInput
              id="minBookingDays"
              name="minBookingDays"
              className={css.description}
              type="number"
              label={intl.formatMessage({ id: 'EditProductForm.minBookingDaysLabel' })}
              placeholder={intl.formatMessage({ id: 'EditProductForm.minBookingDaysPlaceholder' })}
              validate={required(intl.formatMessage({ id: 'EditProductForm.minBookingDaysRequired' }))}
            />

            <FieldTextInput
              id="parts"
              name="parts"
              className={css.description}
              type="textarea"
              label={intl.formatMessage({ id: 'EditProductForm.partsLabel' })}
              placeholder={intl.formatMessage({ id: 'EditProductForm.partsPlaceholder' })}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  )
};

EditProductFormComponent.defaultProps = { className: null, fetchErrors: null };

export default compose(injectIntl)(EditProductFormComponent);
