import React, { useRef } from 'react';
import { string, arrayOf } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import SearchForm from './SearchForm';
import { MainFiltersForm } from '../../forms';
import Video from './bikes.mp4';
import ArrowDown from './arrow_down.svg';
import { useExperiment } from '../../hooks/useExperiment.hook';
import { ExperimentId, ExperimentVariant } from '../../abTest';

import css from './SectionHero.css';
import { propTypes } from '../../util/types';

const SectionHero = props => {
  const { rootClassName, className, onScroll, intl, categoriesSuggestions, history, location, viewport } = props;
  const classes = classNames(rootClassName || css.root, className);

  // const { Variant: TitleVariant } = useExperiment({
  //   id: ExperimentId.HOME_TITLE,
  //   variants: {
  //     [ExperimentVariant.ORIGINAL]: (
  //       <>
  //         <h1 className={css.heroMainTitle}>
  //           Wypożyczaj i&nbsp;uprawiaj sport
  //           {/* <FormattedMessage id="SectionHero.title" /> */}
  //         </h1>
  //         <h2 className={css.heroSubTitle}>
  //           <FormattedMessage id="SectionHero.subTitle" />
  //         </h2>
  //       </>
  //     ),
  //     [ExperimentVariant.A]: (
  //       <h1 className={css.heroMainTitleAlternative}>
  //         Wypożyczaj sprawdzony&nbsp;sprzęt{'\n'}sportowy i turystyczny!
  //         {/* <FormattedMessage id="SectionHero.title" /> */}
  //       </h1>
  //     ),
  //   },
  // });

  const countriesWithAnotherImg =  ['hu', 'cz', 'sk'];
  const isAnotherImg = !!countriesWithAnotherImg.find(item => item === process.env.REACT_APP_COUNTRY_ID);

  return (
    <div className={classes}>
      <div className={css.video}>
        <div className={css.overlay}/>
        <div className={classNames(css.img,{ [css.czLanding]: isAnotherImg })}/>
      </div>

      {/* <video autoPlay playsInline className={css.video} loop muted>
        <source src={Video} type="video/mp4" />
        <track default="" kind="captions" />
      </video> */}
      <div className={css.heroContent}>
        {/* <TitleVariant /> */}
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.mainTitle" />
        </h1>
        <MainFiltersForm
          intl={intl}
          location={location}
          history={history}
          categoriesSuggestions={categoriesSuggestions}
          formContainerClassName={css.heroForm}
          buttonSearchClassName={css.submitButton}
          locationInputClassName={css.customSelectInput}
          locationInputFocusClassName={css.customSelectInputFocus}
          listingInputClassName={css.customSelectInput}
          listingInputFocusClassName={css.customSelectInputFocus}
          predictionsClassName={css.predictions}
          suggestionsWrapperClassName={css.suggestionsWrapper}
          locationWrapperClassName={css.locationWrapper}
          wrapperClassName={css.suggestionWrapper}
        />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  categoriesSuggestions: arrayOf(propTypes.categoriesSuggestions).isRequired,
};

export default SectionHero;
