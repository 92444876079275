import moment from 'moment';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_CONSOLE_TRANSACTIONS_REQUEST =
  'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_REQUEST';
export const FETCH_CONSOLE_TRANSACTIONS_SUCCESS =
  'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_SUCCESS';
export const FETCH_CONSOLE_TRANSACTIONS_ERROR =
  'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_ERROR';

export const EXPORT_TRANSACTIONS_REQUEST =
  'app/ConsoleTransactionPage/EXPORT_TRANSACTIONS_REQUEST';
export const EXPORT_TRANSACTIONS_SUCCESS =
  'app/ConsoleTransactionPage/EXPORT_TRANSACTIONS_SUCCESS';
export const EXPORT_TRANSACTIONS_ERROR =
  'app/ConsoleTransactionPage/EXPORT_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchTransactionListError: null,
  pagination: null,
  transactionList: [],
  exportInProgress: false,
  exportTransactionListError: null,
};

export default function consoleTransactionsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_TRANSACTIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchTransactionListError: null };
    case FETCH_CONSOLE_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        transactionList: payload.data.data,
        pagination: payload.data.meta,
        fetchTransactionListError: null
      };
    }
    case FETCH_CONSOLE_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        pagination: null,
        transactionList: [],
        fetchInProgress: false,
        fetchTransactionListError: payload
      };

    case EXPORT_TRANSACTIONS_REQUEST:
      return { ...state, exportInProgress: true, exportTransactionListError: null };
    case EXPORT_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        exportInProgress: false,
        exportTransactionListError: null
      };
    }
    case EXPORT_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        exportInProgress: false,
        exportTransactionListError: payload
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchTransactionListRequest = () => ({ type: FETCH_CONSOLE_TRANSACTIONS_REQUEST });

const fetchTransactionListSuccess = response => ({
  type: FETCH_CONSOLE_TRANSACTIONS_SUCCESS,
  payload: response,
});

const fetchTransactionListError = e => ({
  type: FETCH_CONSOLE_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

const exportTransactionListRequest = () => ({ type: EXPORT_TRANSACTIONS_REQUEST });

const exportTransactionListSuccess = () => ({
  type: EXPORT_TRANSACTIONS_SUCCESS,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 100;

export const exportTransactionsToXlsx = (search) => async (dispatch, getState, sdk) => {
  const {
    userId,
    processState,
    countryId = config.custom.countryId,
    nonDeliveredOnTime,
    hasPartnership,
    phrase,
    voucherCode,
    businessCategory,
    start,
    end
  } = parse(search);

  const userIdParam = userId ? { userId } : {};
  const voucherCodeParam = voucherCode ? { voucherCode } : {};
  const processStateParam = processState ? { processState } : {};
  const hasPartnershipParam = hasPartnership ? { hasPartnership } : {};
  const nonDeliveredOnTimeParam = nonDeliveredOnTime ? { nonDeliveredOnTime } : {};
  const phraseParam = phrase ? { phrase } : {};
  const businessCategoryParam = businessCategory ? { businessCategory } : {};
  const createdAtFromParam = start ? { createdAtFrom: start } : {};
  const createdAtToParam = end ? { createdAtTo: moment(end).add(1, 'days').format('YYYY-MM-DD') } : {};

  const queryParams = {
    include: ['provider', 'user', 'listings', 'listings.catalogItem.catalogProduct'],
    countryId,
    ...userIdParam,
    ...voucherCodeParam,
    ...processStateParam,
    ...nonDeliveredOnTimeParam,
    ...hasPartnershipParam,
    ...phraseParam,
    ...businessCategoryParam,
    ...createdAtFromParam,
    ...createdAtToParam
  };

  dispatch(exportTransactionListRequest());

  const formatDate = item => {
    return {
    id: item.id.uuid,
    code: item.attributes.code,
    start: item.attributes.start,
    end: moment(item.attributes.end).add(-1, 'days').format("YYYY-MM-DD"),
    user: `${item.attributes.firstName} ${item.attributes.lastName}`,
    userEmail: item.attributes.email,
    provider: `${item.rels.provider.attributes.name} (${item.rels.provider.attributes.countryId})`,
    payinTotal: item.attributes.payinTotal.amount,
    currency: item.attributes.payinTotal.currency,
    createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm"),
    lastTransitionedAt: moment(item.attributes.lastTransitionedAt).format("YYYY-MM-DD HH:mm"),
    processState: item.attributes.processState,
    state: item.attributes.state,
    listings: `${item.rels.listings.map((el, index) => (`${index + 1}. ${el.attributes.title} - ${el.rels.catalogItem?.rels?.catalogProduct?.attributes.modelCode || 'n/a'}`))}`
  }}

  const fileName = {
    filename: 'transactions'
  }

  return sdk.newSdk.transactions
    .xlsx(queryParams, formatDate, fileName)
    .then(() => dispatch(exportTransactionListSuccess()))
    .catch(e => {
      throw e;
    });
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const {
    page = 1,
    userId,
    processState,
    countryId = config.custom.countryId,
    nonDeliveredOnTime,
    hasPartnership,
    phrase,
    voucherCode,
    sort,
    businessCategory,
    start,
    end
  } = parse(search);

  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const businessCategoryParam = businessCategory ? { businessCategory } : {};
  const createdAtFromParam = start ? { createdAtFrom: start } : {};
  const createdAtToParam = start ? { createdAtTo: moment(end).add(1, 'days').format('YYYY-MM-DD') } : {};

  const queryParams = {
    ...paginationParams,
    include: ['provider', 'user', 'listings'],
    userId,
    voucherCode,
    processState,
    countryId,
    nonDeliveredOnTime,
    hasPartnership,
    phrase,
    sort,
    ...businessCategoryParam,
    ...createdAtFromParam,
    ...createdAtToParam
  };

  dispatch(fetchTransactionListRequest());

  return await sdk.newSdk.transactions
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionListError(e));
      throw e;
    });
};
